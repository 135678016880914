"use client"; // Indique que c'est un composant client

import { useState } from 'react';
import { useRouter } from 'next/navigation' ;

const LoginForm = ({ setErrorMessage }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const router = useRouter();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/users/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });

            if (res.ok) {
                const data = await res.json();
                localStorage.setItem('token', data.token); // Enregistrer le token dans localStorage
                router.push('/admin'); // Redirige vers la page admin
            } else {
                const errorData = await res.json();
                setErrorMessage(errorData.error || "Erreur de connexion");
            }
        } catch (error) {
            console.error("Error during login:", error);
            setErrorMessage("Erreur de connexion au serveur");
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-lg font-bold">Connexion</h2>
            <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
            </div>
            <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Mot de passe:</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
            </div>
            <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-500">Se connecter</button>
        </form>
    );
};

const LoginPage = () => {
    const [errorMessage, setErrorMessage] = useState('');

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
                {errorMessage && <p className="text-red-500">{errorMessage}</p>} {/* Afficher les messages d'erreur */}
                <LoginForm setErrorMessage={setErrorMessage} />
            </div>
        </div>
    );
};

export default LoginPage;



